@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --main-color: #ff7166;
  --secondary-color: #ffd1a8;
  --dark-color: #ffb456;
  --light-color: #f1f9ff;
  --current-color: #fff;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, -10%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, -10%, 0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@font-face {
  font-family: "Bebas Regular";
  src: url("./style/fonts/BebasNeueRegular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Hurme Geometric Bold";
  src: url("./style/fonts/HurmeGeometricSans1-Bold.otf") format("opentype");
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("./style/fonts/HelveticaNeueLTStd-Roman.otf") format("opentype");
}

.font-bebas {
  font-family: "Bebas Regular", sans-serif;
}

.fadeIn {
  animation-name: fadeIn;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}
.fadeOut {
  animation-name: fadeOut;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

.fadeInUp {
  animation-name: fadeInUp;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

.fadeOutUp {
  animation-name: fadeOutUp;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

#pagination_wrapper {
  display: flex;
  justify-content: center;
}

.disabled_like {
  pointer-events: none;
  /* cursor: wait; */
}

.navbar-axione > ul > li {
  text-transform: uppercase;
  font-family: "Bebas Regular", sans-serif;
  color: var(--main-color) !important;
  letter-spacing: 2px;
  font-size: 17px;
  padding: 0px 20px;
}

.navbar-axione > ul > li > a {
  color: var(--current-color) !important;
}

.navbar-axione > ul > li > a[class="nav-link btn-axione"] {
  background: #28348b61;
  padding: 8px 40px !important;
  color: #fff !important;
  border-radius: 30px !important;
  border: 1px solid #fff;
  transition: all 0.2s ease-in;
}

.navbar-axione > ul > li > a[class="nav-link btn-axione"]:hover {
  background: var(--dark-color);
}

.btn-axione-animation {
  height: 43px;
  width: 206px;
  overflow: hidden;
  border-radius: 25px;
  transition: all 0.2s ease-in;
  margin: 20px auto;
  text-transform: uppercase;
  background-color: var(--main-color);
  border: 1px solid white;
  box-shadow: 0px 3px 16px #00239a29;
}

.btn-axione-animation > a {
  position: absolute;
  text-align: center;
  z-index: 5;
  height: 43px;
  width: 206px;
  color: var(--current-color) !important;
  font-family: "Bebas Regular", sans-serif;
  letter-spacing: 2px;
  font-size: 17px;
  text-decoration: none !important;
  line-height: 43px;
  padding: 0px;
}

.btn-axione-animation > div {
  height: 43px;
  width: 206px;
  background-color: var(--main-color);
  transition: all 0.2s ease-in;
  transform: scaleX(0);
  margin-left: -206px;
}

.btn-axione-animation:hover > div,
.btn-axione-animation:focus > div {
  transform: scaleX(1);
  margin-left: 0px;
}

.container-triple-h {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  color: white;
  background-color: var(--dark-color);
  border: 1px solid var(--dark-color);
  border-radius: 100%;
  width: 200px;
  height: 200px;
}

#buttonUpload {
  transition: all 0.2s ease-in;
}

#buttonUpload:hover {
  background-color: var(--dark-color);
  color: var(--current-color);
  transform: scale(1.1);
}

.btn-guide-div,
.btn-guide-div > a,
.btn-guide-div > div {
  width: 290px;
}

.btn-guide-div {
  background-color: var(--dark-color);
  border-radius: 40px;
}

.btn-guide-div > a {
  font-size: 20px;
  margin: auto;
}

.hidden {
  display: none;
}

.shareModal {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ff7166;
  z-index: 99999;
}

.social-modal {
  width: 436px;
  height: 184px;
  background-color: #fff;
  padding: 20px 30px 20px;
}

.social-modal > div {
  display: flex;
  align-items: center;
}

.social-modal .return {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.social-modal .return > p {
  font-size: 14px;
  margin: 0;
  color: var(--main-color);
  text-decoration: none;
}

.social-modal-content {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-around;
}

.social-modal-content figure {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
}

.social-modal-content figure > img {
  width: 58px;
  height: 58px;
}

.social-modal-content figure > figcaption {
  font-size: 14px;
  font-family: "Helvetica Neue", sans-serif !important;
  color: var(--main-color);
}

.btn-guide-div > div {
  background-color: var(--dark-color);
}

.btn-succes-upload {
  background-color: var(--main-color);
}

.btn-succes-upload > div {
  background-color: var(--dark-color);
}

.btn-upload-footer {
  background-color: var(--current-color);
  margin: 0px;
  border: 1px solid var(--main-color);
  box-shadow: none;
}

.btn-upload-footer > a {
  color: var(--main-color) !important;
}

.btn-upload-footer:hover > a {
  color: var(--current-color) !important;
}

.btn-upload-nav {
  height: 43px;
  width: 206px;
  overflow: hidden;
  border-radius: 25px;
  transition: all 0.2s ease-in;
  border: 1px solid var(--current-color);
}

#upload-btn-player {
  border: 1px solid var(--main-color);
}

#upload-btn-player > a {
  color: var(--main-color) !important;
}

.btn-upload-nav > a {
  position: absolute;
  text-align: center;
  z-index: 5;
  height: 43px;
  width: 206px;
  color: var(--current-color) !important;
}

.btn-upload-nav > div {
  height: 43px;
  width: 206px;
  background-color: var(--dark-color);
  transition: all 0.2s ease-in;
  transform: scaleX(0);
  margin-left: -206px;
}

.btn-upload-nav:hover > div,
.btn-upload-nav:focus > div {
  transform: scaleX(1);
  margin-left: 0px;
}

.navbar-axione-video > ul > li {
  text-transform: uppercase;
  font-family: "Bebas Regular", sans-serif;
  color: var(--main-color) !important;
  letter-spacing: 2px;
  font-size: 17px;
  padding: 0px 20px;
}

.navbar-axione-video > ul > li > a {
  color: var(--main-color) !important;
}

.navbar-axione-video > ul > li > a[class="nav-link btn-axione"] {
  background-color: var(--main-color);
  padding: 8px 40px !important;
  color: #fff !important;
  border-radius: 30px !important;
  border: 1px solid #fff;
  box-shadow: none;
}

.navbar-home {
  z-index: 5;
  padding-left: 30px !important;
  padding-right: 30px !important;
  width: 100%;
  background: transparent !important;
}

.NavbarVideo {
  background-color: var(--current-color) !important;
}

.navbar-absolute[class*="navbar-home"] {
  position: absolute;
}
.navbar-fixed-scroll {
  transition: all 0.2s ease-in-out;
  background-color: var(--main-color) !important;
  position: fixed;
}

.navbar-brand > img {
  width: 128px;
}

.item-current-scroll {
  height: 100vh;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  z-index: 4;
}

.item-current-scroll > div > div {
  border: 1px solid white;
  height: 15px;
  width: 15px;
  margin: 30px 25px;
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.item-default-scroll > div > div {
  background-color: var(--current-color);
}

.item-on-scroll > div > div {
  background-color: var(--main-color);
}

.item-current-scroll > div > div:hover {
  background-color: var(--dark-color);
}

#active-item-current-scroll {
  width: 36px;
}

.btn-axione {
  background-color: var(--dark-color);
  padding: 8px 60px !important;
  font-family: "Bebas Regular", sans-serif;
  color: var(--current-color) !important;
  font-family: "Bebas Regular", sans-serif;
  letter-spacing: 2px;
  font-size: 17px;
  padding: 0px 20px;
  border-radius: 30px !important;
  box-shadow: 0px 3px 16px #00239a29;
}

#particles-js {
  height: 100vh;
  width: 100%;
  position: fixed;
}

#loader {
  width: 100%;
}

.header-home {
  background-image: url("./style/img/elements/background-1.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
  background-attachment: fixed;
  z-index: -2;
}

.tongs {
  bottom: -5%;
  left: calc(50% - 75px);
  transform: translate(-50%, 50%);
  z-index: 10;
  width: 150px;
  transition: all 0.2s;
}

.rectangle-header {
  position: absolute;
}

.rectangle-gauche {
  top: 10%;
  left: 0;
}

.rectangle-droite {
  bottom: 10%;
  right: 0;
}

.defiler {
  bottom: 0%;
  left: 5%;
}

.grue {
  bottom: 0;
  left: 0;
  width: 14%;
  margin-left: 6.1%;
  transition: all 0.2s;
}

main {
  z-index: 1;
}

#annee-header {
  /* background-image: url("./style/img/elements/annee_2022_bg.svg"); */
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin-top: -20px;
  margin-bottom: 30px;
}

.header-container {
  min-height: 100vh;
  width: 100%;
}

.header-container > div:nth-child(1) {
  font-family: "Hurme Geometric Bold", sans-serif;
  color: var(--main-color);
}

.header-container > div:nth-child(1) > h5 {
  font-family: "Hurme Geometric Bold", sans-serif;
  color: var(--current-color);
  font-size: 18px;
}

.header-container > div:nth-child(1) > h4 {
  font-family: "Hurme Geometric Bold", sans-serif;
  color: var(--current-color);
  font-size: 50px;
}

.header-container > div:nth-child(1) > h2 {
  font-family: "Hurme Geometric Bold", sans-serif;
  color: var(--main-color);
  font-size: 90px;
  margin-top: -10px;
}

.header-container > div:nth-child(1) > h1 {
  font-family: "Hurme Geometric Bold", sans-serif;
  color: var(--main-color);
  font-size: 75px;
  margin-top: -20px;
}

.header-container > div:nth-child(1) > p {
  font-family: "Helvetica Neue", sans-serif;
  color: var(--current-color);
  font-size: 14px;
  width: 80%;
  margin: auto;
}

#videos .video-container {
  padding: 40px !important;
}

.video-container > div {
  position: relative;
  width: 100%;
}

#top1-video {
  width: 65%;
  margin: auto;
}

#top1-video > div {
  box-shadow: 4px 6px 6px #00000029;
}

#top1-video > h1 {
  color: var(--current-color);
}

#top1-video > p {
  color: var(--dark-color);
}

.video-container > h1 {
  font-size: 20px;
  font-family: "Bebas Regular", sans-serif;
  letter-spacing: 2px;
  margin-top: 8px;
}

.video-container > p {
  font-size: 12px;
  font-family: "Helvetica Neue", sans-serif;
  margin-top: -8px;
}

.video-container > div::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.video-container > div > a > div {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #00000023;
  z-index: 1;
}

.video-container > div > a > img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.action-barre {
  width: 100%;
  position: absolute;
  z-index: 2;
  bottom: 0;
  padding: 15px 20px;
  text-decoration: none !important;
}

.player-icon {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 26px;
  height: 26px;
}

.player-icon > img {
  width: 26px;
  transition: all 0.2s ease-in-out;
  transform: scale(1);
}

.video-container > div:hover > .player-icon > img {
  transform: scale(1.3);
}
.like-button {
  display: flex;
  align-items: center;
  cursor: not-allowed;
}

.text-danger {
  font-family: "Helvetica Neue", sans-serif !important;
  font-size: 14px;
}

.text-danger > a {
  font-family: "Helvetica Neue", sans-serif !important;
  font-size: 14px;
  color: #dc3545 !important;
  font-weight: bold;
}

.like-button > span {
  font-family: "Bebas Regular", sans-serif;
  letter-spacing: 2px;
  font-size: 17px;
  padding-left: 10px;
  color: var(--current-color);
}

.like-button > div {
  width: 30px;
  height: 30px;
}

.like-button > div > img {
  width: 30px;
  position: absolute;
}

.share-button > img {
  width: 26px;
}

#videos {
  width: 100%;
  padding: 50px 0px;
  background-color: var(--current-color);
  transform: scale(1);
}

#videos > div > #top2,
#top3,
#top4 {
  padding: 0px 28px;
}

#videos > div > #top2 > h1,
#top3 > h1,
#top4 > h1 {
  color: var(--dark-color);
}

#top2 > p,
#top3 > p,
#top4 > p {
  color: var(--dark-color) !important;
}

#videos > div:nth-child(2) > div {
  padding: 25px;
}

#videos > div:nth-child(2) .like-button > div {
  width: 14px;
  height: 14px;
}
#videos > div:nth-child(2) .like-button > div > img {
  width: 14px;
}

#videos > div:nth-child(2) .share-button > img {
  width: 14px;
}

#videos > div:nth-child(2) .action-barre {
  padding: 7px 11px;
}

.dark-bg-video {
  height: 100%;
  width: 100%;
  background-color: #00000060;
  z-index: 1;
  position: absolute;
  transition: all 0.2s ease-in;
}
.dark-bg-video:hover {
  background-color: #00000035;
}

@keyframes likedefault {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0);
    opacity: 0;
  }
}

@keyframes likeactive {
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.like-animation img:nth-child(1) {
  opacity: 0;
}

.like-active img:nth-child(1) {
  animation: likeactive 0.5s forwards ease-in-out;
}

.like-active img:nth-child(2) {
  animation: likedefault 0.5s forwards ease-in-out;
}

.previous-page img {
  transform: rotate(180deg);
}

.page-item > a {
  font-family: "Bebas Regular", sans-serif;
  letter-spacing: 2px;
  font-size: 16px;
}

.video-upload-section {
  font-family: "Helvetica Neue", sans-serif;
}

.video-upload-section > .upload-section {
  width: 60%;
  background-color: var(--main-color);
  padding: 20px 34px;
}

.upload-section input::placeholder {
  color: white;
  opacity: 0.5;
}

.image-container {
  width: 100%;
  height: 100%;
}

.image-container > div {
  width: 432px;
  margin: 70px auto auto auto;
}

.video-upload-section > .submit-section {
  padding: 24px 34px;
  width: 45%;
}

.video-upload-section > .submit-section > p {
  font-family: "Helvetica Neue", sans-serif;
  color: var(--main-color);
  font-size: 14px;
}

.submit-section > .sous-title {
  font-family: "Helvetica Neue", sans-serif;
  font-weight: bold;
  text-decoration: underline;
  font-size: 12px;
  color: var(--main-color);
}

.form-control-custom {
  background-color: transparent !important;
  border: 2px solid #ffd1a8 !important;
  color: #ffd1a8 !important;
  outline: none !important;
  border-radius: 5px;
  font-size: 14px;
  padding: 10px 12px 8px 12px;
  width: 100%;
}

.form-control-custom:focus {
  outline: none !important;
  box-shadow: none;
}

.form-control-custom::placeholder {
  color: var(--current-color) !important;
}

.modal-content {
  border-radius: 0px !important;
  border: 0px !important;
}

#ModalUpload {
  background: rgb(255, 111, 102);
  background: linear-gradient(
    90deg,
    rgba(255, 111, 102, 1) 0%,
    rgba(255, 118, 102, 1) 10%,
    rgba(255, 129, 103, 1) 20%,
    rgba(255, 140, 104, 1) 30%,
    rgba(255, 152, 105, 1) 40%,
    rgba(255, 164, 106, 1) 50%,
    rgba(255, 177, 107, 1) 60%,
    rgba(255, 189, 108, 1) 70%,
    rgba(255, 202, 109, 1) 80%,
    rgba(255, 214, 110, 1) 90%,
    rgba(242, 200, 121, 1) 100%
  );
}

.custom-file,
.custom-file-input {
  height: 240px;
  width: 100%;
  cursor: pointer;
}

.action-barre-player .like-button > span {
  color: var(--main-color);
}

.dropfile-zone {
  height: 240px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--current-color) !important;
  border-radius: 5px;
  background-color: #ffffff21;
}

.dropfile-zone > p > img {
  width: 80px;
  margin-bottom: 10px;
}

.dropfile-zone > p > p {
  font-family: "Helvetica Neue", sans-serif;
  color: #ffd1a8;
  font-size: 16px;
  font-weight: bold;
}

.dropfile-zone > p > label {
  font-family: "Helvetica Neue", sans-serif;
  color: var(--current-color);
  font-size: 14px;
  text-decoration: underline;
  width: 80%;
}

.dropfile-zone > p {
  margin-bottom: 0 !important;
  position: absolute;
  text-align: center;
  color: var(--current-color);
}
.dropfile-zone > p > span {
  font-size: 10px;
  text-decoration: underline;
}

.form-check-label {
  color: var(--main-color);
  font-size: 10px;
}

.btn-upload-video {
  margin-top: 10px;
  background-color: var(--main-color);
  width: 100%;
  border-radius: 20px;
  font-family: "Bebas Regular", sans-serif;
  letter-spacing: 2px;
  font-size: 17px;
  color: var(--current-color);
  text-align: center;
  padding: 8px 0px 6px 0px;
}

.close-modal-upload > span:nth-child(1) {
  font-size: 50px;
}
.close-modal-upload > span:nth-child(2) {
  font-family: "Bebas Regular", sans-serif;
  letter-spacing: 2px;
  font-size: 14px;
  margin-left: 10px;
}
.close-modal-upload {
  display: flex;
  align-items: center;
  color: var(--current-color);
  text-decoration: none;
  height: 30px;
  margin-bottom: 18px;
}
.close-modal-upload:hover {
  text-decoration: none;
  color: white;
}

#ModalShare .close-modal-upload {
  display: flex;
  align-items: center;
  color: var(--main-color);
  text-decoration: none;
  margin-top: -25px;
  margin-left: 5px;
}

.share-section-modal a {
  padding: 25px;
  text-align: center;
  text-decoration: none;
}

.share-section-modal p {
  font-size: 14px;
  font-family: "Helvetica Neue", sans-serif;
  color: var(--main-color);
  margin-top: 10px;
}

.share-section-modal img {
  width: 58px;
  height: 58px;
}

.video-player {
  width: 100%;
  height: calc(100vh - 60px);
}

.video-player video {
  width: 100%;
  background-color: black;
  outline: none;
  height: 70vh;
}

.title-video {
  margin: 15px 0px 10px 0px;
  font-family: "Helvetica Neue", sans-serif;
}

.title-video > h4 {
  margin-bottom: 0 !important;
}

.action-barre-player {
  height: 50px;
}

.action-barre-player > .like-button > span {
  color: var(--main-color);
}

.action-barre-player .share-section > p:first-child {
  margin: 0px 10px 0px 0px;
}

.action-barre-player .share-section > p:last-child {
  margin: 0px 0px 0px 20px;
}

.action-barre-player .share-section > a {
  padding: 0 4px;
}

.info-barre-player {
  height: 50px;
  border-bottom: 1px solid var(--main-color);
}

#interview {
  position: relative;
  width: 100%;
  min-height: 70vh;
  background-image: url("./style/img/elements/background-2.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  background-attachment: fixed;
}

#interview .container > div {
  background-color: #fff;
}

#interview .video-container > div {
  box-shadow: 0px 0px 20px 0px #3e4796;
}

#interview h1 {
  font-size: 30px;
  color: var(--current-color);
  letter-spacing: 5px;
  font-family: "Hurme Geometric Bold", sans-serif;
  text-transform: uppercase;
  margin-bottom: 35px;
}

#interview p {
  font-size: 14px;
  color: var(--current-color);
  font-family: "Helvetica Neue", sans-serif;
  width: 100%;
  line-height: 24px;
  text-align: right;
  margin-top: 33px;
}

#interview #text {
  display: flex;
  flex-direction: column;
  width: 30%;
  align-items: center;
  color: white;
}

#interview strong {
  text-transform: uppercase;
}

#interview > div:first-child {
  position: absolute;
  width: 100%;
  min-height: 70vh;
}

#interview > div:last-child {
  width: 100%;
  padding: 150px 0px;
}

#participer {
  padding: 60px;
  position: relative;
  width: 100%;
  min-height: 70vh;
  background-image: url("./style/img/elements/background-2.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* overflow: hidden; */
  background-attachment: fixed;
}

#participer #womanComputer {
  position: absolute;
  bottom: 0;
  left: -40px;
  z-index: 2;
}

#participer #sunGlasses {
  position: absolute;
  top: -30px;
  right: 80px;
  width: 200px;
  z-index: 2;
}

#participer > .participer__container {
  background-color: var(--current-color);
  box-shadow: 0px 3px 31px #28348b4d;
  padding: 40px 8%;
  border-radius: 7px;
}

#participer h1 {
  font-family: "Hurme Geometric Bold", sans-serif;
  font-size: 30px;
  letter-spacing: 3px;
  color: var(--main-color);
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 10px;
}

#participer h1 > br {
  display: none;
}

#participer p,
p > a {
  margin-top: 8px;
  font-size: 20px;
  line-height: 25px;
  color: var(--main-color);
  font-family: "Bebas Regular", sans-serif;
}

#participer h6 {
  margin-top: 8px;
  font-size: 14px;
  line-height: 16px;
  color: var(--main-color);
  font-family: "Bebas Regular", sans-serif;
}

#participer > div > div {
  padding: 20px 0px;
}

#participer > div > div:nth-child(2) {
  padding: 20px 0px 20px 20px !important;
}

#participer > div > div:nth-child(3) {
  padding: 20px 0px 20px 20px !important;
}

#participer h2 {
  font-family: "Hurme Geometric Bold", sans-serif;
  font-size: 20px;
  letter-spacing: 2px;
  color: var(--main-color);
  text-transform: uppercase;
  text-align: center;
}

.btn-guide {
  text-align: center;
}

.btn-guide > a {
  text-transform: uppercase;
  font-family: "Bebas Regular", sans-serif;
  color: var(--current-color) !important;
  background-color: var(--main-color);
  letter-spacing: 2px;
  font-size: 20px;
  padding: 11px 40px;
  border-radius: 40px;
  text-decoration: none;
  display: inline-block;
}

#ModalCodir video {
  outline: none;
}

#ModalCodir .close-modal-upload {
  position: absolute;
  z-index: 2;
  margin-top: -20px;
  margin-left: 10px;
}

footer {
  padding: 20px 5%;
  transform: scale(1);
  background-color: var(--current-color);
}

footer > div:nth-child(2) > ul {
  list-style: none;
  border-left: 1px solid var(--main-color);
  margin: 0 !important;
}

footer > div:nth-child(2) > ul > a {
  margin: 10px;
}

footer > div:nth-child(2) > ul > li {
  padding: 0px 10px;
}

footer > div:nth-child(2) > ul > li:last-child {
  padding: 0px 10px 10px 10px;
}

footer > div:nth-child(2) > ul > li > a {
  font-size: 14px;
  color: var(--main-color);
  font-family: "Helvetica Neue", sans-serif;
  text-decoration: none;
}

footer > div:nth-child(2) > ul > li > a:hover {
  text-decoration: underline;
}

.btn-footer-upload {
  text-transform: uppercase;
  font-family: "Bebas Regular", sans-serif;
  color: var(--main-color) !important;
  border: 1px solid var(--main-color);
  letter-spacing: 2px;
  font-size: 20px;
  padding: 3px 30px;
  border-radius: 40px;
  text-decoration: none;
  display: inline-block;
}

.logo-axione-footer {
  width: 100%;
  display: flex;
  padding-bottom: 20px;
}

.logo-axione-footer > img {
  width: 200px;
}

footer > div:nth-child(3) {
  text-align: right;
}

footer > div:nth-child(3) > div > div > p {
  font-size: 14px;
  margin: 0px;
  text-transform: uppercase;
  color: var(--main-color);
  font-family: "Helvetica Neue", sans-serif;
  padding-right: 5px;
}

footer > div:nth-child(3) > div > div > a {
  padding: 7px;
}

footer > div:nth-child(3) > div > div > img {
  height: 30px;
  width: 30px;
  transition: all 0.5s ease-in;
}

footer > div:nth-child(3) > div > div > a:hover > img {
  transform: scale(1.1);
}

.error-container {
  height: calc(100vh - 60px);
  width: 100%;
}

.error-container > div {
  text-align: center;
}

.error-container img {
  max-width: 450px;
}

.error-container h1 {
  font-family: "Helvetica Neue", sans-serif;
  font-size: 25px;
  color: var(--main-color);
  margin-top: 20px;
}

.error-container a {
  margin-top: 30px;
  font-family: "Bebas Regular", sans-serif;
  background-color: var(--main-color);
  color: var(--current-color);
  font-size: 20px;
  padding: 5px 40px;
  border-radius: 40px;
  display: inline-block;
  text-decoration: none;
  letter-spacing: 2px;
}

.loader-axione {
  height: 100vh;
  width: 100%;
  z-index: 10;
  background-color: var(--secondary-color);
  position: absolute;
}

.logo-loading {
  width: 200px;
  margin-bottom: 50px;
}

.nofoundvideo {
  background-color: var(--secondary-color);
}

.nofoundvideo > p {
  color: var(--main-color);
  font-family: "Hurme Geometric Bold", sans-serif;
  text-align: center;
}

.nofoundvideo > p > a {
  margin-top: 10px;
  background-color: var(--main-color);
  color: var(--current-color);
  text-decoration: none;
  padding: 5px 20px 2px 20px;
  border-radius: 20px;
  display: inline-block;
}

#instagramShare {
  text-align: center;
  display: none;
}

#instagramShare > p,
.redlvideo {
  font-size: 12px;
  font-family: "Helvetica Neue", sans-serif;
  color: var(--main-color);
  line-height: 14px;
  margin-bottom: 10px;
}

.btn-share-insta {
  font-family: "Bebas Regular", sans-serif;
  color: var(--main-color);
  padding: 5px 22px;
  border: 1px solid var(--main-color);
  letter-spacing: 1.2px;
  border-radius: 20px;
  display: inline-block;
  text-decoration: none;
  margin: 16px 0px 24px 0px;
  font-size: 12px;
  background-color: transparent;
  transition: all 0.2s ease-in-out;
}

.btn-share-insta:hover {
  background-color: var(--main-color);
  color: var(--current-color);
  text-decoration: none;
}

#pagination_wrapper .pagination > li {
  font-size: 16px;
  font-family: "Bebas Regular", sans-serif;
  color: #28348b !important;
}

#pagination_wrapper .pagination > li > a {
  padding: 15px !important;
  color: var(--main-color) !important;
  text-decoration: none;
}

#pagination_wrapper .pagination > li:hover {
  background-color: #00000023;
}

/* premier LI */
#pagination_wrapper .pagination > li > a {
  background-size: 14px;
}

/* premier LI */
#pagination_wrapper .pagination > li:first-child > a {
  color: transparent !important;
  background-image: url("./style/img/icons/arrow-last-before.svg");
  background-repeat: no-repeat;
  background-position: center;
  transform: rotate(180deg);
}

/* deuxième LI */
#pagination_wrapper .pagination > li:nth-child(2) > a {
  color: transparent !important;
  background-image: url("./style/img/icons/arrow-before.svg");
  background-repeat: no-repeat;
  background-position: center;
  transform: rotate(180deg);
  background-size: 10px;
}

/* avant-dernier LI */
#pagination_wrapper .pagination > li:nth-last-of-type(2) > a {
  color: transparent !important;
  background-image: url("./style/img/icons/arrow-after.svg");
  background-repeat: no-repeat;
  background-position: center;
  transform: rotate(180deg);
  background-size: 10px;
}

/* dernier LI */
#pagination_wrapper .pagination > li:last-child > a {
  color: transparent !important;
  background-image: url("./style/img/icons/arrow-last-after.svg");
  background-repeat: no-repeat;
  background-position: center;
  transform: rotate(180deg);
}

/* dernier LI */
#pagination_wrapper .pagination > .disabled > a {
  opacity: 0.5;
  cursor: not-allowed !important;
}
#pagination_wrapper .pagination > .disabled:hover {
  background-color: transparent !important;
}

#pagination_wrapper .pagination > .active {
  text-decoration: underline;
}

.legal-informations p,
.legal-informations a {
  font-family: "Helvetica Neue", sans-serif !important;
  font-size: 1rem;
}

.legal-informations h1 {
  background-color: var(--main-color);
  color: var(--current-color);
  display: inline-block;
  padding: 5px 20px;
  margin-top: 50px;
  margin-bottom: 20px;
}

.legal-informations h2,
.legal-informations h3,
.legal-informations h4 {
  font-family: "Hurme Geometric Bold", sans-serif;
  color: var(--main-color);
}

.legal-informations h3 {
  margin-top: 50px;
}

#succesuploadcontainer h3 {
  font-family: "Hurme Geometric Bold", sans-serif;
  color: var(--main-color);
  font-size: 30px;
  padding: 30px 40px 20px 40px !important;
  margin-bottom: 0px !important;
}

#succesuploadcontainer p {
  font-family: "Helvetica Neue", sans-serif !important;
  color: var(--main-color);
  font-size: 14px;
}

@media (max-width: 1445px) {
  #participer h1 > br {
    display: block;
  }
}
@media (min-width: 990px) {
  .header-container > div:nth-child(1) {
    padding-right: 0px !important;
  }
}
@media (max-width: 990px) {
  #top1-video {
    width: 95%;
    margin: auto auto 30px auto;
  }
  .navbar-home {
    background-color: var(--main-color) !important;
  }
  .NavbarVideo {
    background-color: var(--current-color) !important;
  }
  .video-upload-section {
    flex-direction: column;
  }
  .video-upload-section > div {
    width: 100% !important;
  }
  .grue {
    display: none;
  }
  #interview {
    height: 100vh;
  }
  #interview > div:first-child {
    height: 100vh;
  }
  footer > div:nth-child(2) > ul {
    border-left: 0px;
    text-align: center;
    padding-left: 0px;
  }
  footer > div:nth-child(3) {
    text-align: center;
  }
  .error-container img {
    width: 100%;
  }
  #annee-header > img {
    width: 100%;
  }
  .item-current-scroll {
    display: none;
  }
  .btn-guide-div,
  .btn-guide-div > a,
  .btn-guide-div > div {
    width: 200px;
    height: 65px;
  }
  .btn-upload-footer {
    margin: auto;
  }
  .btn-axione-animation > a {
    line-height: 20px;
    /* margin-top: 13px; */
  }
  #interview p {
    padding-right: 0px;
    margin: auto;
    text-align: center;
    width: 100%;
  }

  #participer > div > div:nth-child(2) {
    padding: 20px 0px 20px 0px !important;
  }

  #participer > div > div:nth-child(3) {
    padding: 20px 0px 20px 0px !important;
  }

  #participer #sunGlasses {
    top: -30px;
    right: 40px;
    width: 150px;
  }

  #participer #womanComputer {
    left: 0px;
    width: 150px;
  }
  #img-home-tongs {
    display: none;
  }
  .header-home {
  }

  #participer {
    padding: 60px 20px;
  }
}

.nav-lang-choice-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nav-lang-choice-container > select {
  background-color: transparent;
  border: 0;
  color: white;
  margin: 0px;
  width: 40px;
  font-weight: bold;
}

.nav-lang-choice-container > select > option {
  color: black;
}
